<template>
  <div class="quick-setup">
    <b-button variant="primary" size="sm" v-b-modal.modal-quick-setup>Quick Setup</b-button>
    <b-modal ref="modalQuickSetup" id="modal-quick-setup" title="Quick Setup" hide-footer>
      <FormTemplate v-if="form" :form="form">
        <div class="form-group">
            <label class="label-for-xl" for="inputName">Where do you want to use CALLSTR? Provide domain here:</label>
            <b-form-input
                type="text" id="inputName" class="form-control form-control-xl"
                placeholder="enter domain name"
                v-model="form.data.domain"
                :state="form.states.domain"
            />
            <b-form-text>
              <strong>Examples:</strong><br>
              <b-row>
                <b-col cols="6">Domain only:</b-col>
                <b-col cols="6">mydomain.com</b-col>
              </b-row>
              <b-row>
                <b-col cols="6">Sub-Domain only:</b-col>
                <b-col cols="6">subdomain.mydomain.com</b-col>
              </b-row>
              <b-row>
                <b-col cols="6">All domains and subdomains:</b-col>
                <b-col cols="6">*.mydomain.com</b-col>
              </b-row>
            </b-form-text>
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.domain }}</b-form-invalid-feedback>
        </div>
        <template v-slot:buttons>
          <div class="border-top mt-3 pt-3 text-right">
            <button type="submit" class="btn btn-xl btn-primary" :disabled="form.submitButton.disabled">
                <b-spinner small v-if="form.submitButton.disabled"></b-spinner>
                <span class="sr-only" v-if="form.submitButton.disabled">Loading...</span>
                <span  v-if="!form.submitButton.disabled">
                    Create
                </span>
            </button>
          </div>
        </template>
      </FormTemplate>
    </b-modal>
  </div>
</template>
<script>
import {Form} from '@/common/utils/parnekt';
export default {
    props: ['user'],
    components: {
      FormTemplate: () => import('@/common/components/Form/Form'),
    },
    data(){
      return {
        form: null
      };
    },
    mounted(){
      this.form = new Form({domain: ""},{
          onSubmit: async (form) => {
            let domain = form.get('domain');
            let channel = await this.$api.post('channel', {
                data: {
                  name: domain,
                  origins: [
                    {domain: domain}
                  ]
                }
            })
            .then(res => {
              return res.data;
            });

            return this.$api.post('widget',{
              data: {
                name: domain,
                channelId: channel.id,
                locale: "en",
                widgetName: "Bubble"
              }
            });
          },
          onSuccess: (form) => {
            form.submitButton.disabled = true;
            setTimeout(() => {
              document.location.reload();
            },2000);
          },
          onError: function(form){
            form.addError('domain','Please provide a valid domain name');
          }
      });
    }
}
</script>
